<template>
  <div v-if="!angemeldeterMitarbeiter" class="container-fluid tile-container">
    <div class="row mt-70-px">
      <div class="col-4"></div>
      <div class="col-4">
        <div class="row">
          <div class="col-xl-12 col-lg-6 col-md-6">
            <div class="tile-categorie-frame">
              <div class="tile-categorie">
                <div class="header text-center mt-4">
                  <span>Passwort zurücksetzen</span>
                </div>
                <div class="m-4" v-if="!emailSent">
                  <div class="mb-3 text-center">
                    Bitte fülle die E-mail-Adresse ein, die mit deinem Konto
                    verknüpft ist.
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span id="username" class="input-group-text">
                        <font-awesome-icon
                          icon="fa-regular fa-envelope"
                          aria-hidden="true"
                        />
                      </span>
                    </div>
                    <input
                      type="email"
                      name="email"
                      class="form-control"
                      placeholder="E-mail"
                      aria-describedby="email"
                      autofocus
                      autocapitalize="off"
                      spellcheck="false"
                      autocorrect="off"
                      v-model.trim="email"
                    />
                  </div>
                  <div
                    class="text-center hover-medium-blue mb-3"
                    style="cursor: pointer"
                    @click="navigateToLogin"
                  >
                    Zurück zum Login
                  </div>
                  <button
                    class="btn btn-primary w-100 mb-3"
                    @click="resetPassword"
                    :disabled="!email"
                  >
                    Link zum Zurücksetzen anfordern
                  </button>
                </div>

                <div class="m-4" v-if="emailSent">
                  <div class="mb-3 text-center">
                    <h5>
                      Sollte die eingegebene E-Mail-Adresse registriert sein,
                      haben wir eine E-Mail an
                      <span style="text-decoration: underline">{{
                        email
                      }}</span>
                      gesendet.
                    </h5>
                  </div>
                  <div class="row align-items-center;">
                    <div class="col-10">
                      <div class="mt-3">Keine Nachricht erhalten?</div>
                      <ul class="mb-3">
                        <li>Überprüfen Sie Ihren Spam-Ordner</li>
                        <li>Überprüfen Sie Ihre Angaben</li>
                        <li>
                          Warten Sie bitte 15 Minuten, bevor Sie es erneut
                          versuchen
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="align-items-center hover-medium-blue text-center"
                    style="cursor: pointer"
                    @click="navigateToLogin"
                  >
                    Zurück zum Login
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4"></div>
    </div>
  </div>
</template>

<script>
//import Api from "@/Api";
import axios from "axios";
import server from "../server";

export default {
  name: "PasswortReset",
  data() {
    return {
      email: null,
      emailSent: false,
    };
  },
  computed: {
    resetPW_URL() {
      if (process.env.NODE_ENV === "development") {
        return `${server.url}/password/reset/`;
      } else {
        return "/password/reset/";
      }
    },
  },
  methods: {
    async resetPassword() {
      const params = new URLSearchParams();
      params.append("email", this.email);
      this.emailSent = true;
      try {
        axios.post(this.resetPW_URL, params);
      } catch (error) {
        console.log(error);
      }
    },

    navigateToLogin() {
      this.$router.push({
        name: "login",
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.form-signin {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 400px;
  padding: 15px;
}
</style>
